import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Tilt from 'react-parallax-tilt'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import tachyons from 'tachyons-components'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import GlobalStyle from '../styles/GlobalStyle'

const Heading = tachyons(`h1`)`
f2 mt0 mb3 fw6 lh-copy
`
const Container = tachyons(`div`)`
flex items-center-l justify-between flex-column flex-row-reverse-l
`
const VerticalCentering = tachyons('div')`
cf v-mid dtc ph0 pt5 pb6
`
const ContainerTitle = tachyons(`div`)`
mb4 mb0-l w-40-l w-100 tc tr-l avatar self-center self-end-l
`;
const ContainerMain = tachyons(`div`)`
w-60-l w-100
`
const Section = tachyons(`div`)`
wrap-nav w-100 vh-100 dt z-1
`
const ButtonPrimary = tachyons(Link)`
button w-100 w-auto-l no-underline br2 ph4 pv3 dib mr3-ns bg-primary button-primary tc mt2
`
const ButtonSecondary = tachyons(Link)`
button w-100 w-auto-l no-underline br2 ph4 pv3 dib tc bg-secondary bs-secondary br-secondary mt3 
`

export const IndexPageTemplate = ({ 
  domain, 
  heading, 
  primaryButton, 
  secondaryButton, 
  avatar, 
  content, 
  contentComponent }) => {

  const PageContent = contentComponent || Content
  const StyledContent = tachyons(PageContent)`
  lh-copy content f4 db mb3
  `
  return (
    <Section>
      <GlobalStyle />
      <Helmet titleTemplate="Ajmal Afif">
        <meta name="twitter:title" content={`Ajmal Afif`} />
        <meta property="og:site_name" content={`Ajmal Afif`} />
        <meta property="og:title" content={`Ajmal Afif`} />
        <meta property="og:image" content={`${domain}/img/og-image@2x.png`} />
        <meta name="twitter:card" content={`summary`} />
        <meta property="og:video" content={`${domain}/img/loop.mp4`} />
        <meta property="og:video:type" content="video/mp4" />
        <meta property="og:video:width" content="640" />
        <meta property="og:video:height" content="360" />
      </Helmet>
      <VerticalCentering>
        <Container>
          <ContainerTitle>
            <Tilt
              tiltReverse={true}
              tiltMaxAngleX="2.5"
              tiltMaxAngleY="5"
              glareMaxOpacity="0.2"
              glareReverse={true}
              glareEnable={true}
              gyroscope={true}
            >
              <PreviewCompatibleImage imageInfo={avatar} />
            </Tilt>
          </ContainerTitle>
          <ContainerMain>
            <Heading>
              {heading}{" "}
              <span role="img" aria-label="emoji">
                👋🏼
              </span>
            </Heading>
            <StyledContent content={content} />
            <ButtonPrimary role="button" to="/projects/">
              {primaryButton}
            </ButtonPrimary>
            <ButtonSecondary role="button" to="/experience/">
              {secondaryButton}
            </ButtonSecondary>
          </ContainerMain>
        </Container>
      </VerticalCentering>
    </Section>
  );
}

IndexPageTemplate.propTypes = {
  content: PropTypes.string,
  contentComponent: PropTypes.func,
  avatar: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  const { markdownRemark: post } = data

  return (
    <Layout>
      <IndexPageTemplate
        domain={data.site.siteMetadata.siteUrl}
        heading={frontmatter.heading}
        primaryButton={frontmatter.primaryButton}
        secondaryButton={frontmatter.secondaryButton}
        avatar={frontmatter.avatar}
        contentComponent={HTMLContent}
        content={post.html}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export const IndexPageQuery = graphql`
  query IndexPage($id: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        description
        heading
        primaryButton
        secondaryButton
        avatar {
          alt
          image {
          childImageSharp {
            fluid(maxWidth: 640, quality: 100) {
              ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage